<template>
  <div class="analytics">
    <!-- Form -->
    <v-form @submit.prevent="getData">
      <v-row class="pb-6 d-flex justify-center">
        <v-col cols="2" class="pt-2">
          <a-select
            v-model="$v.formInputAnalytic.$model"
            :items="analytics"
            v-on:input="changeAnalytic"
            :label="$t('analytic')"
          >
          </a-select>
        </v-col>
        <!-- INPUT FOR PLANOGRAM
          <v-col
          cols="2"
          class="pt-2"
          v-if="
            this.formInputAnalytic !== 'Otimization' &&
            this.formInputAnalytic !== 'Journey' &&
            this.formInputAnalytic !== 'Performance'
          "
        >
          <a-select
            v-model="$v.formInputPlanogram.$model"
            :items="planograms"
            solo
            v-on:input="changePlanogram"
            label="Layout"
            style="width: 200px"
          >
          </a-select>
        </v-col>
        -->
        <v-col
          cols="2"
          class="pt-2"
          v-if="
            this.formInputAnalytic !== 'Anomalies' &&
            this.formInputAnalytic !== 'Otimization'
          "
        >
          <a-date-field-menu
            v-model="$v.formInputStartDate.$model"
            :validator="$v.formInputStartDate"
            v-on:input="resetShow"
            :label="$t('startDate')"
          ></a-date-field-menu>
        </v-col>
        <v-col
          cols="2"
          class="pt-2"
          v-if="
            this.formInputAnalytic !== 'Anomalies' &&
            this.formInputAnalytic !== 'Otimization'
          "
        >
          <a-date-field-menu
            v-model="$v.formInputEndDate.$model"
            :validator="$v.formInputEndDate"
            :min="formInputStartDate"
            v-on:input="resetShow"
            :label="$t('endDate')"
          >
          </a-date-field-menu>
        </v-col>
        <v-col
          cols="2"
          class="pt-2 "
          v-if="
            this.formInputAnalytic !== 'Anomalies' &&
            this.formInputAnalytic !== 'Otimization' &&
            this.formInputAnalytic !== 'Performance'
          "
          ><a-select
            v-model="formInputTimeOfTheDay"
            :validator="$v.formInputTimeOfTheDay"
            v-on:input="resetShow"
            :items="timeOfDay"
            :label="$t('timeOfDay')"
            
          ></a-select
        ></v-col>
        <v-col
          cols="2"
          class="pt-2"
          v-if="this.formInputAnalytic == 'Performance'"
        >
          <a-complete
            v-model="formInputSKU"
            :validator="$v.formInputSKU"
            :items="skus"
            :label="$t('sku')"
            class=""
            v-on:input="resetShow"
          />
        </v-col>
        <v-col
          cols="4"
          class="pt-2 pr-10"
          v-if="this.formInputAnalytic == 'Anomalies'"
        >
          <a-select
            v-model="$v.formInputItem.$model"
            :validator="$v.formInputItem"
            :items="inputItems"
            v-on:input="changeInputItem"
          >
          </a-select>
        </v-col>
        <v-col
          cols="2"
          class="pt-2 ml-8"
          v-if="
            this.formInputAnalytic == 'Otimization' &&
            this.formInputPlanogram == 'Planogram'
          "
          ><a-select
            v-model="formInputSeason"
            :validator="$v.formInputSeason"
            v-on:input="resetShow"
            :items="seasons"
            label="Season"
            class="ml-n8"
          ></a-select
        ></v-col>
        <v-col cols="1" class="pt-5 ml-2">
          <v-hover v-slot="{ hover }">
            <v-btn
              type="submit"
              color="white--text"
              :style="{ 'background-color': hover ? '#022a63' : '#00204d', 'text-transform': 'unset !important' }"
              class=""
              > {{$t('run')}}</v-btn
            >
          </v-hover>
        </v-col>
      </v-row>
    </v-form>
    <!-- !Form -->
    <!-- Analytic result -->
    <PlanogramAnalytics
      v-if="show"
      :analytic="this.formInputAnalytic"
      :planogram="this.loadedPlanogram"
      :startDate="this.formInputStartDate"
      :endDate="this.formInputEndDate"
      :timeOfTheDay="this.timeOfDay.indexOf(this.formInputTimeOfTheDay)"
      :item="this.formInputItem"
      :SKU="this.formInputSKU"
      :season="this.formInputSeason"
    />
    <!-- !Analytic result -->
  </div>
</template>
<script>
import ASelect from "@/components/ASelect.vue";
import AComplete from "@/components/AComplete.vue";
import ADateFieldMenu from "@/components/ADateFieldMenu.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import AppError from "@/utils/AppError.js";
import PlanogramAnalytics from "@/components/PlanogramAnalytics.vue";

export default {
  name: "analytics",

  components: {
    PlanogramAnalytics,
    ASelect,
    ADateFieldMenu,
    AComplete,
  },

  mounted: function () {
    this.getStore();
    this.getSkus();
    this.getLoadedPlanogram();
    this.formInputAnalytic = this.analyticForm ? this.analyticForm : "Heatmap";
    this.changeAnalytic();
    this.formInputItem = this.product;
  },

  mixins: [validationMixin],

  validations: {
    // Form validations
    formInputPlanogram: { required },
    formInputAnalytic: { required },
    formInputItem: { required },
    formInputSeason: { required },
    formInputStartDate: { required },
    formInputEndDate: { required },
    formInputTimeOfTheDay: { required },
    formInputSKU: { required },
  },

  props: {
    analyticForm: {
      type: String,
      required: false,
      default: null,
    },
    product: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      show: false,
      analytics: ["Heatmap", "Performance", "Journey"],
      inputItems: null,
      planograms: null,
      loadedPlanogram: null,
      store: null,
      skus: null,
      // Form inputs
      formInputPlanogram: null,
      formInputItem: null,
      formInputAnalytic: "Heatmap",
      formInputStartDate: null,
      formInputEndDate: null,
      formInputTimeOfTheDay: "Full day",
      formInputSKU: null,
      formInputSeason: null,
    };
  },
  computed: {
    timeOfDay() {
      return [
        "Morning weekday",
        "Afternoon weekday",
        "Night weekday",
        "Morning weekend",
        "Afternoon weekend",
        "Night weekend",
        "Full day",
      ];
    },
    seasons() {
      return ["Summer", "Winter", "Christmas"];
    },
  },
  methods: {
    /**
     * Reset show.
     * @public
     */
    resetShow() {
      this.show = false;
    },
    /**
     * Reset all the fields.
     * @public
     */
    resetFields() {
      this.$v.$reset();
      this.resetShow();
      this.formInputItem = null;
      this.formInputStartDate = null;
      this.formInputEndDate = null;
      this.formInputSKU = null;
      this.formInputSeason = null;
      this.formInputTimeOfTheDay = "Full day";
    },
    /**
     * On planogram change.
     * @public
     */
    changePlanogram() {
      this.resetFields();
    },
    /**
     * Get all the planograms.
     * @public
     */
    getPlanograms() {
      this.planograms = [
        "Planogram",
        "Simulator 1",
        "Simulator 2",
        "Simulator 3",
      ];
      this.formInputPlanogram = this.planograms[0];
    },
    /**
     * Get all the product sku's.
     * @public
     */
    getSkus() {
      this.skus = ["32123", "2332", "3221", "4123", "1234"];
    },
    /**
     * Get the selected store.
     * @public
     */
    getStore() {
      this.store = JSON.parse(localStorage.getItem("store"));
    },
    /**
     * Get the loaded planogram.
     * @public
     */
    getLoadedPlanogram() {
      let url = this.$config.api.route.planogram;
      url = url.replace(":id", this.store.id);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
          this.loadedPlanogram = resp.data;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .then(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Get analytic data.
     * @public
     */
    getData() {
      switch (this.formInputAnalytic) {
        case "Performance":
          this.$v.formInputStartDate.$touch();
          this.$v.formInputEndDate.$touch();
          this.$v.formInputSKU.$touch();
          if (
            this.$v.formInputStartDate.$invalid ||
            this.$v.formInputEndDate.$invalid ||
            this.$v.formInputSKU.$invalid
          ) {
            return;
          }
          this.show = true;
          break;
        case "Anomalies":
          this.$v.formInputItem.$touch();
          if (this.$v.formInputItem.$invalid) {
            return;
          }
          this.show = true;
          break;
        case "Otimization":
          this.$v.formInputSeason.$touch();
          if (this.$v.formInputSeason.$invalid) {
            return;
          }
          this.show = true;
          break;
        default:
          this.$v.formInputStartDate.$touch();
          this.$v.formInputEndDate.$touch();
          if (
            this.$v.formInputStartDate.$invalid ||
            this.$v.formInputEndDate.$invalid
          ) {
            return;
          }
          this.show = true;
          break;
      }
    },
    /**
     * Return items depending on the analytic.
     * @public
     */
    changeInputItem() {
      this.inputItems = [
        "G TA M367 IMPASTO FRESCO PANE/PIZZA 1KG",
        "ORT LENTICCHIE SGOC.240G",
        "PIEMONTE BARBERA DOC 13%VOL 0.75L",
        "M23567 PY FILONCINO FERRARESE 250G",
        "CUBA LIBRE E MOJITO 5% VOL 275ML",
      ];
      this.resetShow();
    },
    /**
     * On analytic change.
     * @public
     */
    changeAnalytic() {
      this.resetFields();
      this.changeInputItem(this.formInputAnalytic);
      this.formInputPlanogram = "Planogram";
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  box-shadow: none !important;
}
</style>
