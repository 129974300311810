<template>
  <div>
    <!-- Flow score -->
    <v-row
      class="d-flex justify-end mt-2"
      v-if="this.analytic == 'Otimization'"
    >
      <h4 class="mb-n4 mr-3">
        Flow score: {{ score }}/5 ({{ percentageIncrease }}%)
      </h4>
    </v-row>
    <!-- !Flow score -->
    <!-- Otimization -->
    <v-row
      class="d-flex justify-center mt-6"
      v-if="this.analytic == 'Otimization'"
    >
      <v-col>
        <v-data-table
          :headers="otimizationHeaders"
          :items="otimizationItems"
          :items-per-page="5"
          class="elevation-0"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
          }"
        ></v-data-table>
      </v-col>
    </v-row>
    <!-- !Otimization -->
    <!-- Changes in shoppers flow -->
    <v-row
      class="d-flex justify-center mt-10"
      v-if="this.analytic == 'Otimization'"
      >Changes in shoppers flow
    </v-row>
    <v-row class="mt-4" v-if="this.analytic == 'Otimization'">
      <v-col cols="12">
        <PlanogramNodes
          v-if="this.showOtimization"
          :planogram="this.planogram"
          :nodes="this.nodes"
          class="mt-n12"
        />
      </v-col>
    </v-row>
    <!-- !Changes in shoppers flow -->
    <!-- Statistics -->
    <div
      class="analytics__wrapper"
      v-if="this.analytic == 'Anomalies' || this.analytic == 'Performance'"
    >
      <div class="analytics__score analytics-score">
        <div class="analytics-score__container">
          <h1 class="analytics-score__text">
            {{ units }} <br />
            <span class="analytics-score__text--size">{{
              $t("unitsMonth")
            }}</span>
          </h1>
        </div>
        <div class="analytics-score__container">
          <h1 class="analytics-score__text">
            {{ conversionRate }} % <br />
            <span class="analytics-score__text--size">{{
              $t("conversionRate")
            }}</span>
          </h1>
        </div>
        <div
          class="analytics-score__container"
          v-if="this.analytic == 'Anomalies'"
        >
          <h1 class="analytics-score__text">
            {{ saleIncrease }} % <br />
            <span class="aanalytics-score__text--size"
              >Potential sales increase</span
            >
          </h1>
        </div>
        <div
          class="analytics-score__container analytics-score__container--inverse"
          v-if="this.analytic == 'Performance' && this.error"
        >
          <p class="analytics-score__text--size">
            {{ errorMessage }}
          </p>
        </div>
      </div>
    </div>

    <!-- !Statistics -->
    <!-- Segments -->
    <div v-if="this.analytic == 'Anomalies' || this.analytic == 'Performance'">
      <h3 class="tables__title">
        {{ $t("segments") }} {{ $t("targetShoppers") }}
      </h3>
      <table class="tables__container">
        <thead class="tables__header">
          <tr class="tables__row">
            <th class="tables__cell-title">{{ headersSegments[0].text }}</th>
            <th class="tables__cell-title">{{ headersSegments[1].text }}</th>
            <th class="tables__cell-title">{{ headersSegments[2].text }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in segmentItems"
            :key="item.percentage"
            class="your-row-class"
          >
            <td class="tables__cell">{{ item.percentage }} %</td>
            <td class="tables__cell">{{ item.basket }}</td>
            <td class="tables__cell">{{ item.impulsive }}</td>
          </tr>
        </tbody>
      </table>

      <!-- <v-data-table
        :headers="headersSegments"
        :items="this.segmentItems"
        dense
        hide-default-footer
      >
        <template v-slot:[`item.percentage`]="{ item }">
          {{ item.percentage }} %
        </template>
      </v-data-table> -->
    </div>
    <!-- !Segments -->
    <!-- Journeys -->
    <v-row v-if="this.analytic == 'Anomalies'">
      <v-col cols="12">
        <v-card
          class="d-flex align-center pa-12"
          style="border-radius: 30px"
          elevation="0"
        >
          <v-col cols="2">
            <v-row class="d-flex justify-center">
              <h4 class="primary--text">Journeys with top</h4>
            </v-row>
            <v-row class="d-flex justify-center">
              <h4 class="primary--text">2nd locations</h4>
            </v-row>
          </v-col>
          <v-col cols="10">
            <PlanogramNodes
              v-if="this.showAnomalies"
              :planogram="this.planogram"
              :nodes="this.nodes"
              class="mt-n12"
            />
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <!-- !Journeys -->
    <!-- Daily sales -->
    <v-row v-if="this.analytic == 'Performance'">
      <v-col cols="12">
        <v-card
          class="d-flex align-center pa-12"
          style="border-radius: 30px"
          elevation="0"
        >
          <v-col cols="2">
            <v-row class="d-flex justify-center">
              <h4 class="primary--text">{{ $t("dailySales") }}</h4>
            </v-row>
            <v-row class="d-flex justify-center">
              <h4 class="primary--text">({{ $t("quantity") }})</h4>
            </v-row>
          </v-col>
          <v-col cols="10">
            <line-chart
              v-if="lineChartData != null"
              :series="lineChartData"
              :cats="cats"
            />
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <!-- !Daily sales -->
    <!-- % Total transactions by segments -->
    <v-row
      v-if="
        this.analytic == 'Performance' &&
        this.errorMessage == 'Niche product detected'
      "
    >
      <v-col cols="12">
        <v-card
          class="d-flex align-center pa-12"
          style="border-radius: 30px"
          elevation="0"
        >
          <v-col cols="2">
            <v-row class="d-flex justify-center">
              <h4 class="primary--text">% {{ $t("totalTransactions") }}</h4>
            </v-row>
            <v-row class="d-flex justify-center">
              <h4 class="primary--text">{{ $t("bySegments") }}</h4>
            </v-row>
          </v-col>
          <v-col cols="10">
            <v-row class="d-flex justify-center">
              <h3 class="primary--text mt-1">{{ oldValue }}%</h3>
              <v-icon large color="secondary" class="px-4" width>
                mdi-arrow-right-thin
              </v-icon>
              <h3 class="secondary--text mt-1">{{ newValue }}%</h3>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <!-- !% Total transactions by segments -->
    <!-- Change in consumer behaviour -->
    <div v-if="this.analytic == 'Performance'">
      <h3 class="tables__title">
        {{ $t("changeInConsumer") }} {{ $t("behaviour") }}
      </h3>
      <table class="tables__container">
        <thead class="tables__header">
          <tr class="tables__row">
            <th class="tables__cell-title">{{ headersChange[0].text }}</th>
            <th class="tables__cell-title">{{ headersChange[1].text }}</th>
            <th class="tables__cell-title">{{ headersChange[2].text }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in changeItems"
            :key="item.cannibalisation"
            class="your-row-class"
          >
            <td class="tables__cell">{{ item.cannibalisation }} %</td>
            <td class="tables__cell">{{ item.sku }}</td>
            <td class="tables__cell">{{ item.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- !Change in consumer behaviour -->
    <!-- Journey Basket and Potencial Add -->
    <v-row
      v-if="this.analytic == 'Journey'"
      class="d-flex justify-center mt-n6"
    >
      <table class="tables__container">
        <thead class="tables__header">
          <tr class="tables__row">
            <th class="tables__cell-title tables__cell-title--auto">
              {{ this.$t("segmentID") }}
            </th>
            <th class="tables__cell-title tables__cell-title--auto">
              {{ this.$t("totalShoppers") }}
            </th>
            <th class="tables__cell-title tables__cell-title--auto">
              {{ this.$t("mainBasket") }}
            </th>
            <th class="tables__cell-title tables__cell-title--auto">
              {{ this.$t("potentialIncrease") }}
            </th>
            <th class="tables__cell-title tables__cell-title--auto">
              {{ this.$t("valueEuro") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in displayedItems" :key="item.id" :style="{ 'background-color': item.id == formInputSegmentID ? '#f7c98c' : '' }">
            <td class="tables__cell">{{ item.id }}</td>
            <td class="tables__cell">{{ item.shoppers }}</td>
            <td class="tables__cell">{{ item.basket }}</td>
            <td class="tables__cell">{{ item.increase }}</td>
            <td class="tables__cell">{{ item.value }}</td>
          </tr>
        </tbody>
      </table>

      <!-- Pagination -->
      <div class="pagination">
        <button class="pagination__button" @click="prevPage" :disabled="currentPage <= 1">&lt;</button>
        <button class="pagination__button"
          v-for="n in totalPages"
          :key="n"
          @click="goToPage(n)"
          :class="{ active: currentPage === n }"
        >
          {{ n }}
        </button>
        <button class="pagination__button" @click="nextPage" :disabled="currentPage >= totalPages">
          >
        </button>
      </div>
    </v-row>
    <!-- !Journey Basket and Potencial Add -->
    <!-- Segment ID -->
    <div v-if="this.analytic == 'Journey'" class="journey">
      <div class="journey__container">
        <p class="journey__text">{{ $t("selectSegmentID") }}:</p>
      </div>
      <div class="journey__container">
        <v-select
          v-model="formInputSegmentID"
          class="mt-1"
          :items="ids"
          @change="changeSegment"
          solo
          style="width: 80px"
        ></v-select>
      </div>
      <div class="journey__container">
        <v-hover v-slot="{ hover }">
          <v-btn
            @click="selectSegment"
            type="submit"
            class="mt-n6"
            color="white--text"
            :style="{ 'background-color': hover ? '#022a63' : '#00204d' }"
            >{{ $t("select") }}</v-btn
          >
        </v-hover>
      </div>
    </div>

    <!-- <v-row class="d-flex justify-center pt-2 mb-n12" v-if="this.analytic == 'Journey'">
      <v-col cols="2" class="pt-8">
        <p class="">select Segment ID:</p>
      </v-col>
      <v-col cols="2" class="pt-4"><v-select v-model="formInputSegmentID" :items="ids" @change="changeSegment" solo
          style="width: 80px"></v-select></v-col>
      <v-col cols="2" class="pl-6 pt-6">
        <v-hover v-slot="{ hover }">
          <v-btn @click="selectSegment" type="submit" color="white--text"
            :style="{ 'background-color': hover ? '#022a63' : '#00204d' }" class="ml-n12">Select</v-btn>
        </v-hover>
      </v-col>
    </v-row> -->
    <!-- !Segment ID -->
    <!-- Planogram -->
    <v-row
      class="d-flex justify-center"
      v-if="
        this.analytic == 'Heatmap' ||
        this.analytic == 'Segments' ||
        this.analytic == 'Journey'
      "
    >
      <v-col>
        <PlanogramNodes
          v-if="this.show"
          :planogram="this.planogram"
          :nodes="this.nodes"
          class="mt-n12"
        />
      </v-col>
    </v-row>
    <!-- !Planogram -->
    <!-- Shopping time and distance -->
    <div v-if="this.analytic == 'Heatmap'" class="heatmap">
      <div class="heatmap__container">
        <p>{{ $t("shoppingTime") }}:&nbsp;</p>
        <p class="heatmap__text">{{ shoppingTime }}</p>
      </div>
      <div class="heatmap__container">
        <p>{{ $t("travelDistance") }}:&nbsp;</p>
        <p class="heatmap__text">{{ travelDistance }}</p>
      </div>
      <div class="heatmap__container">
        <p>{{ $t("numberShoppers") }}:&nbsp;</p>
        <p class="heatmap__text">{{ shoppers }}</p>
      </div>
    </div>
    <!-- !Shopping time and distance -->
    <!-- Number of shoppers -->
    <!-- !Number of shoppers -->
    <!-- Search Code -->
    <v-col v-if="this.analytic == 'Heatmap'">
      <v-row>
        <v-col cols="4" class="ml-n2">
          <v-text-field
            :label="$t('codeExample') + $t('required')"
            v-model="inputCode"
            @change="showSearchMessage = false"
          >
          </v-text-field>
          <p class="red--text mt-n4" v-show="showSearchMessage">
            Please insert a code !
          </p>
        </v-col>
        <v-col cols="4">
          <v-btn @click="searchCode" color="primary" class="mt-4">{{
            $t("search")
          }}</v-btn>
        </v-col>
      </v-row>
      <v-col v-if="this.codeConversion !== null" class="mt-4 ml-n2">
        <v-row>
          <p class="font-weight-bold">{{ $t("conversion") }}:&nbsp;</p>
          <p>{{ this.codeConversion }}</p>
        </v-row>
        <v-row>
          <p class="font-weight-bold">{{ $t("description") }}:&nbsp;</p>
          <p>{{ this.codeDescription }}</p>
        </v-row>
        <v-row>
          <p class="font-weight-bold">{{ $t("location") }}:&nbsp;</p>
          <p>{{ this.codeLocation }}</p>
        </v-row>
        <v-row>
          <p class="font-weight-bold">{{ $t("products") }}:&nbsp;</p>
          <p>{{ this.codeProducts }}</p>
        </v-row>
      </v-col>
    </v-col>
    <!-- Search Code -->
  </div>
</template>

<script>
// @ is an alias to /src
import PlanogramNodes from "@/components/PlanogramNodes.vue";
import LineChart from "@/components/charts/Line.vue";

export default {
  name: "planogram-analytics",

  components: {
    PlanogramNodes,
    LineChart,
  },

  props: {
    planogram: {
      type: Object,
    },
    analytic: {
      type: String,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    timeOfTheDay: {
      type: Number,
    },
    SKU: {
      type: String,
    },
    item: {
      type: String,
    },
    season: {
      type: String,
    },
  },

  mounted: function () {
    this.getAnalyticData();
  },

  data() {
    return {
      loadedDiagram: null,
      formInputSegmentID: 0,
      inputCode: null,
      showSearchMessage: false,

      shoppingTime: null,
      travelDistance: null,
      shoppers: null,
      ids: [],
      segments: [],

      codeConversion: null,
      codeDescription: null,
      codeLocation: null,
      codeProducts: null,

      nodes: [],

      show: false,
      showAnomalies: false,
      showOtimization: false,

      otimizationItems: [],
      score: null,
      percentageIncrease: null,
      segmentItems: [],
      changeItems: [],
      units: null,
      conversionRate: null,
      saleIncrease: null,
      error: false,
      errorMessage: null,
      oldValue: null,
      newValue: null,
      lineChartData: null,
      cats: null,
      currentPage: 1,
      itemsPerPage: 5,
    };
  },

  computed: {
    otimizationHeaders() {
      return [
        {
          text: this.$t("code"),
          align: "start",
          value: "code",
        },
        {
          text: this.$t("category"),
          value: "category",
        },
        {
          text: "Current location",
          value: "location",
        },
        {
          text: "New location",
          value: "newLocation",
        },
        {
          text: "Expected sales (k€)",
          value: "sales",
          filterable: true,
        },
      ];
    },
    headersSegments() {
      return [
        {
          text: "",
          align: "center",
          value: "percentage",
          width: 70,
          sortable: false,
        },
        {
          text: this.$t("mainBasket"),
          value: "basket",
          width: 500,
          sortable: false,
        },
        {
          text: this.$t("impulsiveProducts"),
          value: "impulsive",
          sortable: false,
        },
      ];
    },
    headersChange() {
      return [
        {
          text: this.$t("cannibalisation%"),
          align: "center",
          value: "cannibalisation",
          sortable: false,
        },
        {
          text: this.$t("sku"),
          value: "sku",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("description"),
          value: "description",
          align: "left",
          sortable: false,
        },
      ];
    },
    totalPages() {
      return Math.ceil(this.segments.length / this.itemsPerPage);
    },
    displayedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = this.currentPage * this.itemsPerPage;
      return this.segments.slice(start, end);
    },
  },

  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(n) {
      this.currentPage = n;
    },
    /**
     * Get the analytic data.
     * @public
     */
    getAnalyticData() {
      switch (this.analytic) {
        case "Journey":
          this.getJourneyData(this.startDate, this.endDate, this.timeOfTheDay);
          break;
        case "Heatmap":
          this.getHeatmapData(this.startDate, this.endDate, this.timeOfTheDay);
          break;
        case "Anomalies":
          this.getAnomaliesData(this.item);
          break;
        case "Performance":
          this.getPerformanceData(
            this.startDate,
            this.endDate,
            this.timeOfTheDay,
            this.SKU
          );
          break;
        case "Otimization":
          this.getOtimizationData(this.season);
          break;
      }
    },
    /**
     * Get otimization data.
     * @param {string} season - the season.
     * @public
     */
    getOtimizationData(season) {
      console.log(season);
      this.score = 4.1;
      this.percentageIncrease = "+58";
      this.otimizationItems = [
        {
          code: "EN0C",
          category: "ENERGY",
          location: "5L [2 3 4]",
          newLocation: "2L [1 2 3]",
          sales: 432,
        },
        {
          code: "EN0C",
          category: "ENERGY",
          location: "5L [2 3 4]",
          newLocation: "2L [1 2 3]",
          sales: 432,
        },
        {
          code: "EN0C",
          category: "ENERGY",
          location: "5L [2 3 4]",
          newLocation: "2L [1 2 3]",
          sales: 432,
        },
        {
          code: "EN0C",
          category: "ENERGY",
          location: "5L [2 3 4]",
          newLocation: "2L [1 2 3]",
          sales: 432,
        },
      ];
      this.nodes = [
        {
          color: "#1919ff",
          size: "50 50",
          pos: "50 0",
          category: "heatmap",
        },
        {
          color: "#4c4cff",
          size: "50 50",
          pos: "0 0",
          category: "heatmap",
        },
        {
          color: "#7f7fff",
          size: "50 50",
          pos: "-50 0",
          category: "heatmap",
        },
        {
          color: "#b2b2ff",
          size: "50 50",
          pos: "-100 0",
          category: "heatmap",
        },
        {
          color: "#e5e5ff",
          size: "50 50",
          pos: "-150 0",
          category: "heatmap",
        },
        {
          color: "#ffe5e5",
          size: "50 50",
          pos: "-200 0",
          category: "heatmap",
        },
        {
          color: "#ffb2b2",
          size: "50 50",
          pos: "-250 0",
          category: "heatmap",
        },
        {
          color: "#ff7f7f",
          size: "50 50",
          pos: "-300 0",
          category: "heatmap",
        },
        {
          color: "#ff4c4c",
          size: "50 50",
          pos: "-350 0",
          category: "heatmap",
        },
        {
          color: "#ff1919",
          size: "50 50",
          pos: "-400 0",
          category: "heatmap",
        },
      ];
      this.showOtimization = true;
    },
    /**
     * Get anomalies data.
     * @param {string} item - the product.
     * @public
     */
    getAnomaliesData(item) {
      console.log(item);
      this.units = 30;
      this.conversionRate = 15;
      this.saleIncrease = 10;
      this.segmentItems = [
        {
          percentage: 60,
          basket: "ORO CIOK LATTE",
          impulsive: "M3271",
        },
        {
          percentage: 11,
          basket:
            "ORO CIOK LATTEASDDDDDDDDDDDD DDDDDDDDDDDDDDDDDDDDDDD DDDDDDDDDDFOBSFBUOASFBUOFAS",
          impulsive: "M3271",
        },
        {
          percentage: 29,
          basket: "ORO CIOK LATTE",
          impulsive: "M3271 ASDASDSAD ADSASDASD",
        },
      ];

      this.nodes = [
        {
          color: "#000000",
          size: "50 50",
          pos: "50 0",
          category: "journey",
        },
        {
          color: "#000000",
          size: "50 50",
          pos: "0 50",
          category: "journey",
        },
      ];
      this.showAnomalies = true;
    },
    /**
     * Get performance data.
     * @param {string} startDate - start date.
     * @param {string} endDate - end date.
     * @param {string} timeOfTheDay - time of the day.
     * @param {string} SKU - the product sku.
     * @public
     */
    getPerformanceData(startDate, endDate, SKU) {
      /*
      let url = this.$config.api.route.analytic;
      url = url.replace(":id", this.store.id);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        data: {
          analytic: "performance",
          start_date: startDate,
          end_date: endDate,
          sku: SKU
        },
        method: "POST",
      })
        .then((resp) => {
          console.log(resp.data);
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
      */
      console.log(startDate);
      console.log(endDate);
      console.log(SKU);
      this.units = 30;
      this.conversionRate = 25;
      this.error = true;
      this.errorMessage = "Niche product detected";
      this.segmentItems = [
        {
          percentage: 60,
          basket: "ORO CIOK LATTE",
          impulsive: "M3271",
        },
        {
          percentage: 11,
          basket:
            "ORO CIOK LATTEASDDDDDDDDDDDD DDDDDDDDDDDDDDDDDDDDDDD DDDDDDDDDDFOBSFBUOASFBUOFAS",
          impulsive: "M3271",
        },
        {
          percentage: 29,
          basket: "ORO CIOK LATTE",
          impulsive: "M3271 ASDASDSAD ADSASDASD",
        },
      ];

      this.lineChartData = [
        {
          name: this.$t("dailySales"),
          data: [30, 100, 75, 150],
        },
      ];

      this.cats = ["Jan", "Jul", "Aug", "Dec"];
      this.oldValue = 23;
      this.newValue = 37;

      this.changeItems = [
        {
          cannibalisation: 11,
          sku: 10572,
          description: "MY BIO GALLETE DI RISO 150G",
        },
        {
          cannibalisation: 14,
          sku: 12324,
          description: "MY BIO GALLETE DI RISO 150G",
        },
        {
          cannibalisation: 9,
          sku: 15212,
          description: "GALLETE DI RISO 150G",
        },
        {
          cannibalisation: 23,
          sku: 12321,
          description: "MY BIO GALLETE DI RISO 150G",
        },
        {
          cannibalisation: 3,
          sku: 10572,
          description: "MY BIO GALLETE DI RISO 150G",
        },
        {
          cannibalisation: 6,
          sku: 13214,
          description: "MULINO BAISDAS HJSDASD G",
        },
      ];
    },
    /**
     * Get heatmap data.
     * @param {string} startDate - start date.
     * @param {string} endDate - end date.
     * @param {string} timeOfTheDay - time of the day.
     * @public
     */
    getHeatmapData(startDate, endDate, timeOfTheDay) {
      /*
      let url = this.$config.api.route.analytic;
      url = url.replace(":id", this.store.id);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        data: {
          analytic: "heatmap",
          start_date: startDate,
          end_date: endDate,
          time_of_day: timeOfTheDay
        },
        method: "POST",
      })
        .then((resp) => {
          console.log(resp.data);
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
      */
      console.log(startDate);
      console.log(endDate);
      console.log(timeOfTheDay);
      this.shoppingTime = "15m:13s";
      this.travelDistance = "15m";
      this.shoppers = 34;
      this.nodes = [
        {
          color: "#1919ff",
          size: "50 50",
          pos: "50 0",
          category: "heatmap",
        },
        {
          color: "#4c4cff",
          size: "50 50",
          pos: "0 0",
          category: "heatmap",
        },
        {
          color: "#7f7fff",
          size: "50 50",
          pos: "-50 0",
          category: "heatmap",
        },
        {
          color: "#b2b2ff",
          size: "50 50",
          pos: "-100 0",
          category: "heatmap",
        },
        {
          color: "#e5e5ff",
          size: "50 50",
          pos: "-150 0",
          category: "heatmap",
        },
        {
          color: "#ffe5e5",
          size: "50 50",
          pos: "-200 0",
          category: "heatmap",
        },
        {
          color: "#ffb2b2",
          size: "50 50",
          pos: "-250 0",
          category: "heatmap",
        },
        {
          color: "#ff7f7f",
          size: "50 50",
          pos: "-300 0",
          category: "heatmap",
        },
        {
          color: "#ff4c4c",
          size: "50 50",
          pos: "-350 0",
          category: "heatmap",
        },
        {
          color: "#ff1919",
          size: "50 50",
          pos: "-400 0",
          category: "heatmap",
        },
      ];
      this.show = true;
    },
    /**
     * Get journey data.
     * @param {string} startDate - start date.
     * @param {string} endDate - end date.
     * @param {string} timeOfTheDay - time of the day.
     * @public
     */
    getJourneyData(startDate, endDate, timeOfTheDay) {
      /*
      let url = this.$config.api.route.analytic;
      url = url.replace(":id", this.store.id);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        data: {
          analytic: "journey",
          start_date: startDate,
          end_date: endDate,
          time_of_day: timeOfTheDay
        },
        method: "POST",
      })
        .then((resp) => {
          console.log(resp.data);
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
      */
      console.log(startDate);
      console.log(endDate);
      console.log(timeOfTheDay);
      this.shoppingTime = "15m:13s";
      this.travelDistance = "15m";
      this.ids = [0, 1, 2, 3, 4];
      this.segments = [
        {
          id: 0,
          shoppers: 9,
          basket: [
            "0: POLPA MELA/PESCA VASC. 100G AMONATURA",
            "0: POLPA MELA/PRUGNA VASC. AMONATURA100G",
            "0: POLPA MELA/BANANA VASC. 2X100G AMONATURA",
            "0: POLPA PERA VASCH. 2X100G AMONATURA",
            "0: POLPA MELA/MIRTILLO VASC.AMONATURA 100G",
            "0: POLPA MELA VASCH. 2X100G AMONATURA",
            "0: POLPA MELA/MANGO VASC. 100G AMONATURA",
            "1: LBP TRECCINE 10PZ 420G",
            "2: CARAMELLE MORE LIQUIRIZIA 250G",
            "3: FYD THE PESCA 0.5L",
            "4: PAGNOTTA DI GRANO DURO 350G",
            "5: M367 VAL MOZZARELLA BUFALA DOP 10X50G",
            "6: SDC FILONCINO DI SALAME 250G",
          ],
          increase: ["0: BOT FOCACCINE 35G X5 PZ 175 G"],
          value: 32,
          nodes: [
            {
              color: "#000000",
              size: "50 50",
              pos: "50 0",
              category: "journey",
            },
            {
              color: "#000000",
              size: "50 50",
              pos: "-50 50",
              category: "journey",
            },
          ],
        },
        {
          id: 1,
          shoppers: 8,
          basket: [
            "0: SDC SPALLA COTTA TRANCIO 500G",
            "1: ORT OLIVE VERDI IN SALAMOIA 340G",
            "2: M23567 PY CORNETTO AL FORM. SACCH.200G",
            "3: M23567 PY PATATINE 300G",
            "4: BRAVO ARACHIDI TOSTATE SALATE 500G",
            "5: NY PANNO MICROFIBRA 32X33 2PZ",
            "6: APERITIVO 11% VOL 0.7L",
            "6: M235678 LIQUORE BITTER 25% VOL 0.7L",
            "7: M67 DOLCETTO PIEMONTE DOC 12.5%VOL 0.75L",
            "8: PY PANE SANDWICH 750G",
            "9: SDC SPECK TRANCIO AL KG",
          ],
          increase: [],
          value: 32,
          nodes: [
            {
              color: "#000000",
              size: "50 50",
              pos: "-150 50",
              category: "journey",
            },
          ],
        },
        {
          id: 2,
          shoppers: 2,
          basket: [
            "0: PAMPERS BABY JUNIOR X19",
            "0: MELLIN OMOGENEIZZATO CONIGLIO 4X80G",
            "0: MELLIN OMOGENEIZZATO FRUTTAMISTA  6X100G",
            "0: MELLIN OMOGENEIZZATO POLLO 4X80G",
            "0: MELLIN OMOGENEIZZATO PERA 6X100G",
            "0: MELLIN OMOGENEIZZATO MANZO 4X80G",
            "0: MELLIN OMOG. FAGIOLI/VERDURE ASS 2X80G",
            "0: MELLIN OMOGENEIZZATO VITELLO 4X80G",
            "0: MELLIN CREMA RISO/SEMOLINO ASS.200G",
          ],
          increase: ["0: LIMONI OR. ITA AL KG"],
          value: 32,
        },
        {
          id: 3,
          shoppers: 1,
          basket: [
            "0: CAMOSCIO COMPACT CALZANETTO",
            "1: M3567 BONDUELLE PIS/CAROT.LATTA 265G",
            "1: M23567 BONDUELLE PISELLI FINI 2X265G",
            "0: ANTIACQUA CALZANETTO COM.NEUTRO 150ML",
          ],
          increase: [],
          value: 32,
        },
        {
          id: 4,
          shoppers: 1,
          basket: [
            "0: FEV - SEMI DI GIRASOLE OR.BUL 300G",
            "0: FEV - AVENA DECORTICATA OR.NDL 400G",
            "1: LYSOFORM DISINFETTANTE MEDICAL 250ML",
            "0: FEV-CECI DECORTICATI OR.ITA 300G",
            "0: FEV - CICERCHIA DECORTICATA OR.ITA 300G",
            "0: FEV - FAVE SPEZZATE OR.EGY300G",
            "0: FEV-LENTICCHIA GIALLA DECORT OR.ITA 300G",
          ],
          increase: [],
          value: 32,
          nodes: [
            {
              color: "#000000",
              size: "50 50",
              pos: "50 0",
              category: "journey",
            },
          ],
        },
      ];
      this.nodes = this.segments[0].nodes;
      this.show = true;
    },
    /**
     * Reset search code field.
     * @public
     */
    resetSearchField() {
      this.inputCode = null;
      this.codeConversion = null;
      this.codeDescription = null;
      this.codeLocation = null;
      this.codeProducts = null;
    },
    /**
     * Get the product informations.
     * @public
     */
    searchCode() {
      if (this.inputCode !== null && this.inputCode !== "") {
        this.showSearchMessage = false;
        console.log(this.inputCode);
        this.codeConversion = "passersby: 0, purchased: 0 -> Conversion: 100%";
        this.codeDescription = "EXPO FERRERO";
        this.codeLocation = "aisle 4 L";
        this.codeProducts =
          "FERRERO KINDER CEREALI MINI 107G, FERRERO CIOCCOLATO MINI 120G, FERRERO KINDER LOVE CUORE 37G";
      } else {
        this.showSearchMessage = true;
        this.resetSearchField();
      }
      /*
      var store = JSON.parse(localStorage.getItem("store"));
      let url = this.$config.api.route.usersStores;
      url = url.replace(":id", store.id);
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: url,
        method: "GET",
      })
        .then((resp) => {
            this.codeConversion = resp.data.conversion;
          }
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });*/
    },
    /**
     * Change segment.
     * @public
     */
    changeSegment() {
      this.show = false;
      this.nodes = this.segments[this.formInputSegmentID].nodes;
    },
    /**
     * Select segment to show his planogram.
     * @public
     */
    selectSegment() {
      this.show = true;
    },
    /**
     * Class to change the color of the row if it is selected.
     * @public
     */
    rowClass(item) {
      return item.id === this.formInputSegmentID ? "myclass" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/main.scss";
.heatmap {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.heatmap__container {
  display: flex;
}

.myclass {
  background-color: #f7c98c !important;
}

.v-application p {
  color: #00204d;
}

.heatmap__text {
  padding-left: 8px;
  font-weight: bold;
}

.v-data-table__wrapper {
  margin-top: 3rem;
}

.text-start {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  font-size: 16px !important;
}

.journey {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
}

.v-text-field__details {
  display: none;
}

.tables__title {
  padding: 2rem 0;
}

.analytics-score {
  display: flex;
  align-items: center;
  border-radius: 2rem;
  margin: 3rem 0;
  justify-content: space-evenly;

  &__container {
    text-align: center;
    background: white;
    border-radius: 10px;
    min-width: 210px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90px;

    &--no-bg {
      background: none;
      padding: 0;
    }

    &--inverse {
      background: #00204dd6;

      .analytics-score__text--size {
        color: white;
      }
    }
  }

  &__text {
    color: #002147;
    line-height: 25px;

    &--size {
      font-size: 16px;
      font-weight: 100;
      margin: 0;
    }
  }
}

.active {
  background-color: #00204dd6;
  color: white;
}

.tables__container {
  margin: 3rem 0;
}

.tables__cell-title {
  &--auto {
    min-width: auto;
  }
}

.pagination__button {
    padding: 0.5rem 1rem;
}
</style>
