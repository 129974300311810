<template>
  <v-menu
    v-model="dateMenu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="400"
  >
    <template v-slot:activator="{ on }">
      <a-text-field
        v-model="selected"
        :validator="validator"
        :label="label"
        :on="on"
        prepend-icon="mdi-calendar-month"
      ></a-text-field>
    </template>
    <v-date-picker
      v-model="selected"
      @change="dateMenu = false"
      :min="computedMin"
      no-title
      header-color="primary"
      color="primary"
    ></v-date-picker>
  </v-menu>
</template>

<script>
/**
 * @vue-prop {String} value - Value
 * @vue-prop {String} label - Label
 * @vue-prop {String} min - Min
 * @vue-prop {Object} validator - Validator
 */
// @ is an alias to /src
import { format, parseISO } from "date-fns";
import ATextField from "@/components/ATextField.vue";
export default {
  name: "a-date-field-menu",

  components: {
    ATextField,
  },

  props: {
    value: String,
    label: String,
    min: String,
    validator: Object,
  },

  data() {
    return {
      dateMenu: false,
      date: null,
    };
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    computedDateFormatted() {
      return this.date ? format(parseISO(this.date), "short") : "";
    },

    computedMin() {
      if (this.min) {
        return this.min;
      }

      return undefined;
    },
  },

  watch: {
    date() {
      this.value = this.formatDate(this.date);
    },
  },
};
</script>
